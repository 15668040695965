import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import LeftTextSlideshow from 'components/Slideshows/LeftTextSlideshow'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content, data, locale }) => {
  const { slug } = content

  const locations = data?.locations?.nodes
  const locationsLocale = locations?.map((item) => item.frontmatter[locale])
  const otherLocationsLocale = locationsLocale.filter(
    (item) => item.slug !== slug
  )

  const slideshow = {
    items: otherLocationsLocale.map((item) => {
      return {
        image: item.imageSet.image,
        title: item.title,
        subtitle: item.description,
        link: `/locations/${item.slug}`,
      }
    }),
  }

  const title = {
    en: 'More Locations',
    zh: '更多分布',
  }

  return (
    <div className='block pad-v-standard'>
      <div className='container'>
        <Inview className='fade-in up grid-24'>
          <div className={styles.title}>
            <h5>{title[locale]}</h5>
          </div>
        </Inview>
        <div className={styles.slideshowWrap}>
          <LeftTextSlideshow content={slideshow} gridColumns={24} />
        </div>
      </div>
    </div>
  )
}

export default function Data({ content, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          locations: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "location" } } } }
            sort: { fields: frontmatter___en___order, order: ASC }
          ) {
            nodes {
              frontmatter {
                en {
                  slug
                  title
                  description
                  imageSet {
                    image {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    imageMobile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
                zh {
                  slug
                  title
                  description
                  imageSet {
                    image {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    imageMobile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block content={content} data={data} locale={locale} />}
    />
  )
}
