import React from 'react'
import BannerImageText from 'blocks/BannerImageText/template'
import TextOnlyInset from 'blocks/TextOnlyInset/template'
import LocationsSlideshow from 'blocks/_dynamic/LocationsSlideshow/template'
import HeaderVersionWrap from 'components/HeaderVersionWrap'
import Footer from 'components/Footer'

const ContentType = ({ pageId, content, locale, layoutProps }) => {
  if (!locale) locale = 'en'

  const strings = {
    en: {
      background: 'Background',
    },
    zh: {
      background: '背景情况',
    },
  }

  const pageContent = {
    bannerImageText: {
      pretitle: content.description,
      title: content.title,
      imageSet: content.imageSet,
    },
    textOnlyInset: {
      title: strings[locale].background,
      body: content.body,
      address: content.address,
    },
    locationsSlideshow: {
      slug: content.slug,
    },
  }
  const theme = content.isDarkTheme ? 'dark' : 'light'

  return (
    <div className='page'>
      <div className={`block theme bg-${theme}`}>
        <HeaderVersionWrap
          layoutProps={layoutProps}
          isDark={content.isDarkTheme}
        >
          <BannerImageText
            content={pageContent.bannerImageText}
            layoutProps={layoutProps}
          />
        </HeaderVersionWrap>
      </div>
      <div className='block theme bg-light'>
        <HeaderVersionWrap layoutProps={layoutProps} isDark={false}>
          <TextOnlyInset content={pageContent.textOnlyInset} />
          <div className='theme bg-shade'>
            <LocationsSlideshow
              content={pageContent.locationsSlideshow}
              locale={locale}
            />
          </div>
        </HeaderVersionWrap>
      </div>
      <Footer locale={locale} />
    </div>
  )
}

export default ContentType
