import React from 'react'
import Inview from 'components/Inview'
import MarkdownWrap from 'components/MarkdownWrap'
import * as styles from './styles.module.scss'
import MapMarkerSvg from 'assets/svg/icons/map-marker.svg'

const Block = ({ content }) => {
  const { title, body, address } = content
  return (
    <div className={`block bg-shade ${styles.component}`}>
      <Inview className={`container ${styles.container} grid-24 fade-in up`}>
        <div className={styles.main}>
          {title && <h3>{title}</h3>}
          {body && <MarkdownWrap body={body} />}
          {address && (
            <div className={styles.address}>
              <MapMarkerSvg />
              <p className='smaller'>{address}</p>
            </div>
          )}
        </div>
      </Inview>
    </div>
  )
}

export default Block
